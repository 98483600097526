import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: { input: any; output: any };
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. Format is `yyyy-MM-dd` */
  DateOnly: { input: any; output: any };
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: { input: Date; output: Date };
  Decimal: { input: any; output: any };
  Uri: { input: any; output: any };
};

export type AiThreadResponseQl = {
  __typename?: 'AIThreadResponseQL';
  additionalTexts?: Maybe<AdditionalTextQl>;
  messageId?: Maybe<Scalars['String']['output']>;
  recipeJson?: Maybe<Scalars['String']['output']>;
  replyToUser?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
};

export type ActivityQl = {
  code: Scalars['String']['input'];
  dateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<SelectedTicketQl>>>;
};

export type AdditionalTextQl = {
  __typename?: 'AdditionalTextQL';
  destinations?: Maybe<Array<Maybe<DestinationAdditionalTextQl>>>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl: Scalars['String']['output'];
  inspirationalMessage?: Maybe<Scalars['String']['output']>;
};

export type AgeRangeQl = {
  __typename?: 'AgeRangeQL';
  availableTimeslotsPrices?: Maybe<
    Array<Maybe<AvailableTimeslotsPricesProjectedQl>>
  >;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maxAge?: Maybe<Scalars['Int']['output']>;
  minAge: Scalars['Int']['output'];
  minimumTicketsPerAgeRange?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<MoneyQl>;
  quantityAvailable?: Maybe<Scalars['Int']['output']>;
};

export type AvailableTimeslotsPricesProjectedQl = {
  __typename?: 'AvailableTimeslotsPricesProjectedQL';
  amount: Scalars['Decimal']['output'];
  availabletimeSlotId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  quantityAvailable?: Maybe<Scalars['Int']['output']>;
};

export type BookingTokenRequestQl = {
  activities?: InputMaybe<Array<InputMaybe<ActivityQl>>>;
  market: Scalars['Uri']['input'];
};

export type CancellationPolicyResponseQl = {
  __typename?: 'CancellationPolicyResponseQL';
  amount?: Maybe<MoneyQl>;
  fromDate: Scalars['String']['output'];
  fromDateIso: Scalars['String']['output'];
};

export type CheapestHotelStayRequestQl = {
  day?: InputMaybe<Scalars['DateOnly']['input']>;
  hotelCode: Scalars['String']['input'];
  ingredientIndex: Scalars['Int']['input'];
  recipe: RecipeQl;
};

export type CheapestPriceQl = {
  __typename?: 'CheapestPriceQL';
  date: Scalars['DateTime']['output'];
  placeCode: Scalars['String']['output'];
  totalPrice?: Maybe<MoneyQl>;
};

export type CheapestStayByHotelRequestQl = {
  constraint: ConstraintQl;
  day?: InputMaybe<Scalars['DateOnly']['input']>;
  ingredientIndex: Scalars['Int']['input'];
  recipe: RecipeQl;
};

export type ConstraintQl = {
  destination?: InputMaybe<Scalars['String']['input']>;
  destinationCode?: InputMaybe<Scalars['Int']['input']>;
  hotelCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  polygon?: InputMaybe<Array<InputMaybe<CoordinateQl>>>;
  polygonId?: InputMaybe<Scalars['Int']['input']>;
  serializedConstraint?: InputMaybe<Scalars['String']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};

export type CoordinateQl = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type DayTicketRateQl = {
  __typename?: 'DayTicketRateQL';
  date?: Maybe<Scalars['DateTime']['output']>;
  rates?: Maybe<Array<Maybe<TicketRateQl>>>;
};

export type DecimalRangeTypeQl = {
  from?: InputMaybe<Scalars['Decimal']['input']>;
  to?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DestinationAdditionalTextQl = {
  __typename?: 'DestinationAdditionalTextQL';
  activityTexts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DoubleRangeQl = {
  __typename?: 'DoubleRangeQL';
  from?: Maybe<Scalars['Float']['output']>;
  to?: Maybe<Scalars['Float']['output']>;
};

export type FerryIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'FerryIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  allCarriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  date?: Maybe<Scalars['Date']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  destinationTranslation?: Maybe<Scalars['String']['output']>;
  durationRangeInMinutes?: Maybe<DoubleRangeQl>;
  ferryNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  key?: Maybe<Scalars['String']['output']>;
  legDescription?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxNumberOfStops?: Maybe<Scalars['Int']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originTranslation?: Maybe<Scalars['String']['output']>;
  outBoundArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  outBoundDepartureTime?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type FlightIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'FlightIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  allCarriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  date?: Maybe<Scalars['DateTime']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  destinationTranslation?: Maybe<Scalars['String']['output']>;
  durationRangeInMinutes?: Maybe<DoubleRangeQl>;
  flightNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  key?: Maybe<Scalars['String']['output']>;
  legDescription?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxNumberOfStops?: Maybe<Scalars['Int']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originTranslation?: Maybe<Scalars['String']['output']>;
  outBoundArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  outBoundDepartureTime?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type HeartedHotelQl = {
  code: Scalars['String']['input'];
  isRefundable?: InputMaybe<Scalars['Boolean']['input']>;
  roomCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rooms?: InputMaybe<Array<InputMaybe<HeartedRoomQl>>>;
};

export type HeartedRoomQl = {
  boardBasis: Scalars['String']['input'];
  code: Scalars['String']['input'];
};

export type HotelIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'HotelIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  lengthOfStay?: Maybe<LengthOfStayQl>;
  numberOfNights?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<MoneyQl>;
  subIngredientResults?: Maybe<Array<Maybe<IngredientResponseQl>>>;
  suggested?: Maybe<HotelStayResponseQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type HotelStayResponseQl = {
  __typename?: 'HotelStayResponseQL';
  addToBasketToken: Scalars['String']['output'];
  date: Scalars['DateOnly']['output'];
  hotelCode: Scalars['String']['output'];
  isRefundable: Scalars['Boolean']['output'];
  roomStays?: Maybe<Array<Maybe<RoomStayResponseQl>>>;
  score?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<MoneyQl>;
};

export type ImageResponseQl = {
  __typename?: 'ImageResponseQL';
  imageName: Scalars['String']['output'];
  imageService: Scalars['String']['output'];
};

export type IngredientQl = {
  arrivalTimeFilters?: InputMaybe<StringRangeTypeQl>;
  boardBasis?: InputMaybe<Array<InputMaybe<InputBoardBasisQl>>>;
  carriers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  departureTimeFilters?: InputMaybe<StringRangeTypeQl>;
  destination?: InputMaybe<Scalars['String']['input']>;
  destinationCode?: InputMaybe<Scalars['Int']['input']>;
  flightNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  freeCancellation?: InputMaybe<Scalars['Boolean']['input']>;
  fromIata?: InputMaybe<Scalars['String']['input']>;
  hasParking?: InputMaybe<Scalars['Boolean']['input']>;
  hasPool?: InputMaybe<Scalars['Boolean']['input']>;
  heartedHotels?: InputMaybe<Array<InputMaybe<HeartedHotelQl>>>;
  hotelCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hotelName?: InputMaybe<Scalars['String']['input']>;
  ingredientType?: InputMaybe<InputIngredientTypeQl>;
  maxDurationInHours?: InputMaybe<Scalars['Float']['input']>;
  maxLayoverTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  maxNumberOfHotels?: InputMaybe<Scalars['Int']['input']>;
  maxNumberOfStops?: InputMaybe<Scalars['Int']['input']>;
  numberOfNights?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  petsAreAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  polygon?: InputMaybe<Array<InputMaybe<CoordinateQl>>>;
  polygonId?: InputMaybe<Scalars['Int']['input']>;
  priceFilters?: InputMaybe<DecimalRangeTypeQl>;
  selectedHotelTypes?: InputMaybe<Array<InputMaybe<InputSelectedHotelTypesQl>>>;
  serializedConstraint?: InputMaybe<Scalars['String']['input']>;
  starRating?: InputMaybe<Scalars['Int']['input']>;
  subIngredients?: InputMaybe<Array<InputMaybe<IngredientQl>>>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<SelectedTicketQl>>>;
  toIata?: InputMaybe<Scalars['String']['input']>;
  transportTypes?: InputMaybe<Array<InputMaybe<InputTransportTypeQl>>>;
  vehicleType?: InputMaybe<InputTransportVehicleTypeQl>;
};

export type IngredientResponseQl = {
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export enum InputAvailibityOrderQl {
  /** Cheapest */
  Cheapest = 'CHEAPEST',
  /** None */
  None = 'NONE',
  /** Popular */
  Popular = 'POPULAR',
}

export enum InputBoardBasisQl {
  /** AllInclusive */
  AllInclusive = 'ALL_INCLUSIVE',
  /** Breakfast */
  Breakfast = 'BREAKFAST',
  /** FullBoard */
  FullBoard = 'FULL_BOARD',
  /** HalfBoard */
  HalfBoard = 'HALF_BOARD',
  /** Lunch */
  Lunch = 'LUNCH',
  /** RoomOnly */
  RoomOnly = 'ROOM_ONLY',
}

export enum InputIngredientTypeQl {
  /** Ferry */
  Ferry = 'FERRY',
  /** Flight */
  Flight = 'FLIGHT',
  /** Hotel */
  Hotel = 'HOTEL',
  /** None */
  None = 'NONE',
  /** OwnStay */
  OwnStay = 'OWN_STAY',
  /** Ticket */
  Ticket = 'TICKET',
  /** Transport */
  Transport = 'TRANSPORT',
}

export type InputPaxGroupQl = {
  childAges?: InputMaybe<Array<Scalars['Int']['input']>>;
  numberOfAdults: Scalars['Int']['input'];
  numberOfChildren?: InputMaybe<Scalars['Int']['input']>;
};

export enum InputSelectedHotelTypesQl {
  /** Apartment */
  Apartment = 'APARTMENT',
  /** Golf */
  Golf = 'GOLF',
  /** Hotel */
  Hotel = 'HOTEL',
  /** HouseOrCottage */
  HouseOrCottage = 'HOUSE_OR_COTTAGE',
  /** None */
  None = 'NONE',
  /** Ticket */
  Ticket = 'TICKET',
}

export enum InputSourceTypeQl {
  /** Lab */
  Lab = 'LAB',
  /** MarketPlace */
  MarketPlace = 'MARKET_PLACE',
  /** Planner */
  Planner = 'PLANNER',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export enum InputTransportTypeQl {
  /** Ferry */
  Ferry = 'FERRY',
  /** Flight */
  Flight = 'FLIGHT',
  /** None */
  None = 'NONE',
}

export enum InputTransportVehicleTypeQl {
  /** Bicycle */
  Bicycle = 'BICYCLE',
  /** MiniBus4mHigh6mLong */
  MiniBus_4MHigh_6MLong = 'MINI_BUS_4_M_HIGH_6_M_LONG',
  /** MiniBus8mLong */
  MiniBus_8MLong = 'MINI_BUS_8_M_LONG',
  /** MotorBike */
  MotorBike = 'MOTOR_BIKE',
  /** MultiPurposeVehicle */
  MultiPurposeVehicle = 'MULTI_PURPOSE_VEHICLE',
  /** None */
  None = 'NONE',
  /** Vehicle4m70cmLong */
  Vehicle_4M_70CmLong = 'VEHICLE_4_M_70_CM_LONG',
  /** Vehicle6mLong */
  Vehicle_6MLong = 'VEHICLE_6_M_LONG',
}

export type IntRangeQl = {
  __typename?: 'IntRangeQL';
  from?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['Int']['output']>;
};

export type ItineraryResponseQl = {
  __typename?: 'ItineraryResponseQL';
  itineraryKey: Scalars['String']['output'];
  result?: Maybe<Array<Maybe<IngredientResponseQl>>>;
  totalPrice?: Maybe<MoneyQl>;
};

export type LegQl = {
  __typename?: 'LegQL';
  durationInMinutes: Scalars['Float']['output'];
  segments?: Maybe<Array<Maybe<SegmentQl>>>;
};

export type LengthOfStayQl = {
  __typename?: 'LengthOfStayQL';
  nightsHaveBeenSuggested: Scalars['Boolean']['output'];
  numberOfNights: Scalars['Int']['output'];
};

export type LuggageInformationQl = {
  __typename?: 'LuggageInformationQL';
  included: Scalars['Boolean']['output'];
  pieces?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<LuggageTypeResult>;
  unit?: Maybe<LuggageUnitTypeResult>;
  weight?: Maybe<Scalars['Decimal']['output']>;
};

export enum LuggageTypeResult {
  CabinBag = 'CabinBag',
  CheckedBag = 'CheckedBag',
  None = 'None',
}

export enum LuggageUnitTypeResult {
  Kilograms = 'Kilograms',
  KilogramsAndPieces = 'KilogramsAndPieces',
  None = 'None',
  Pieces = 'Pieces',
}

export type MetadataRequestQl = {
  generatedFromAI?: InputMaybe<Scalars['Boolean']['input']>;
  originUrl?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<InputSourceTypeQl>;
};

export type MissingPropertyQl = {
  __typename?: 'MissingPropertyQL';
  defaultValue?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MissingPropertyType>;
};

export enum MissingPropertyType {
  NumberOfAdults = 'NUMBER_OF_ADULTS',
  NumberOfChildren = 'NUMBER_OF_CHILDREN',
  NumberOfNights = 'NUMBER_OF_NIGHTS',
}

export type MoneyQl = {
  __typename?: 'MoneyQL';
  amount?: Maybe<Scalars['Decimal']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currencySymbol?: Maybe<Scalars['String']['output']>;
};

export type OwnStayResultQl = IngredientResponseQl & {
  __typename?: 'OwnStayResultQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  numberOfNights?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<MoneyQl>;
  subIngredientResults?: Maybe<Array<Maybe<IngredientResponseQl>>>;
  totalPrice?: Maybe<MoneyQl>;
};

export type PaxResponseQl = {
  __typename?: 'PaxResponseQL';
  age?: Maybe<Scalars['Int']['output']>;
};

export type PriceRangeQl = {
  __typename?: 'PriceRangeQL';
  from?: Maybe<MoneyQl>;
  to?: Maybe<MoneyQl>;
};

export type Query = {
  __typename?: 'Query';
  bookingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cheapestItinerary?: Maybe<ItineraryResponseQl>;
  getThreadAI?: Maybe<AiThreadResponseQl>;
  getTransportList?: Maybe<TransportListResponseQl>;
  ratesByDay?: Maybe<Array<Maybe<DayTicketRateQl>>>;
  recipeFromText?: Maybe<RecipeFromTextResponseQl>;
  transportMatrix?: Maybe<TransportMatrixResponseQl>;
  validateRecipe?: Maybe<Scalars['Boolean']['output']>;
};

export type QueryBookingTokensArgs = {
  request: BookingTokenRequestQl;
};

export type QueryCheapestItineraryArgs = {
  recipe: RecipeQl;
};

export type QueryGetThreadAiArgs = {
  request: ThreadAiRequestQl;
};

export type QueryGetTransportListArgs = {
  request: TransportListRequestQl;
};

export type QueryRatesByDayArgs = {
  request: TicketRequestQl;
  skipCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryRecipeFromTextArgs = {
  request: RecipeFromTextRequestQl;
};

export type QueryTransportMatrixArgs = {
  recipe: RecipeQl;
};

export type QueryValidateRecipeArgs = {
  recipe: RecipeQl;
};

export type RecipeFromTextRequestQl = {
  market?: InputMaybe<Scalars['Uri']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  user?: InputMaybe<Scalars['String']['input']>;
};

export type RecipeFromTextResponseQl = {
  __typename?: 'RecipeFromTextResponseQL';
  additionalTexts?: Maybe<AdditionalTextQl>;
  missingProperties?: Maybe<Array<Maybe<MissingPropertyQl>>>;
  recipeJson?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RecipeQl = {
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  featureToggles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ingredients?: InputMaybe<Array<InputMaybe<IngredientQl>>>;
  market: Scalars['Uri']['input'];
  metadata?: InputMaybe<MetadataRequestQl>;
  months?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pax?: InputMaybe<Array<InputMaybe<InputPaxGroupQl>>>;
  returnEmptyIngredientResults?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  skipCache?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  suggestedBy?: InputMaybe<InputAvailibityOrderQl>;
};

export type RoomStayResponseQl = {
  __typename?: 'RoomStayResponseQL';
  boardBasis?: Maybe<Scalars['String']['output']>;
  cancellationPolicies?: Maybe<Array<Maybe<CancellationPolicyResponseQl>>>;
  description: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<ImageResponseQl>>>;
  pax?: Maybe<Array<Maybe<PaxResponseQl>>>;
  price?: Maybe<MoneyQl>;
  roomCode: Scalars['String']['output'];
  taxesAndFees?: Maybe<Array<Maybe<TaxAndFeeResponseQl>>>;
};

export type SegmentQl = {
  __typename?: 'SegmentQL';
  arrivalDateTime: Scalars['DateTime']['output'];
  arrivalIata: Scalars['String']['output'];
  carrier: Scalars['String']['output'];
  departureDateTime: Scalars['DateTime']['output'];
  departureIata: Scalars['String']['output'];
  durationInMinutes: Scalars['Float']['output'];
  flightNumber: Scalars['String']['output'];
  isCabinMandatory: Scalars['Boolean']['output'];
  layoverTimeUntilNextSegmentInMinutes?: Maybe<Scalars['Float']['output']>;
  luggageInformation?: Maybe<Array<Maybe<LuggageInformationQl>>>;
  operatingCarrier: Scalars['String']['output'];
  transportType?: Maybe<TransportType>;
  transportationName?: Maybe<Scalars['String']['output']>;
  vehicleType?: Maybe<TransportVehicleType>;
};

export type SelectedTicketQl = {
  ageRangeId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  rateId: Scalars['String']['input'];
  timeSlotId?: InputMaybe<Scalars['String']['input']>;
};

export type StringRangeTypeQl = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionsAsInRealtimeNotifications = {
  __typename?: 'SubscriptionsAsInRealtimeNotifications';
  getCheapestStayByHotel?: Maybe<CheapestPriceQl>;
  searchCheapestStay?: Maybe<HotelStayResponseQl>;
  searchRecipeByItinerary?: Maybe<ItineraryResponseQl>;
};

export type SubscriptionsAsInRealtimeNotificationsGetCheapestStayByHotelArgs = {
  request: CheapestStayByHotelRequestQl;
};

export type SubscriptionsAsInRealtimeNotificationsSearchCheapestStayArgs = {
  request: CheapestHotelStayRequestQl;
};

export type SubscriptionsAsInRealtimeNotificationsSearchRecipeByItineraryArgs =
  {
    recipe: RecipeQl;
  };

export type TaxAndFeeResponseQl = {
  __typename?: 'TaxAndFeeResponseQL';
  amount?: Maybe<MoneyQl>;
  isIncludedInPrice: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type ThreadAiRequestQl = {
  market?: InputMaybe<Scalars['Uri']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  threadId?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type TicketIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'TicketIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  price?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type TicketRateQl = {
  __typename?: 'TicketRateQL';
  ageRanges?: Maybe<Array<Maybe<AgeRangeQl>>>;
  maximumAmountTickets?: Maybe<Scalars['Int']['output']>;
  minimumAmountTickets?: Maybe<Scalars['Int']['output']>;
  rateId: Scalars['String']['output'];
  requiredAgeRanges?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  timeSlots?: Maybe<Array<Maybe<TimeSlotsQl>>>;
  title: Scalars['String']['output'];
};

export type TicketRequestQl = {
  code: Scalars['String']['input'];
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  fromDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  market: Scalars['Uri']['input'];
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
  toDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
};

export type TimeSlotsQl = {
  __typename?: 'TimeSlotsQL';
  id: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type TransportGroupValueQl = {
  __typename?: 'TransportGroupValueQL';
  carriers: Array<Maybe<Scalars['String']['output']>>;
  combinableWith: Array<Scalars['Int']['output']>;
  departureTime: Scalars['String']['output'];
  durationInMinutes?: Maybe<DoubleRangeQl>;
  hasRestriction: Scalars['Boolean']['output'];
  iataCodes: Array<Maybe<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  isHomeboundRoundtrip: Scalars['Boolean']['output'];
  isOutboundRoundtrip: Scalars['Boolean']['output'];
  landingTime: Scalars['String']['output'];
  price?: Maybe<PriceRangeQl>;
  restrictionWithGroupInIngredient: Scalars['Int']['output'];
  roundtripTiedToIngredient?: Maybe<Scalars['Int']['output']>;
  stops?: Maybe<IntRangeQl>;
  transportsInGroup: Scalars['Int']['output'];
};

export type TransportIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'TransportIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  allCarriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  date?: Maybe<Scalars['DateTime']['output']>;
  durationRangeInMinutes?: Maybe<DoubleRangeQl>;
  key?: Maybe<Scalars['String']['output']>;
  legIndex?: Maybe<Scalars['Int']['output']>;
  legs?: Maybe<Array<Maybe<LegQl>>>;
  price?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type TransportListRequestQl = {
  dayOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  elementsInPage: Scalars['Int']['input'];
  ingredientIndex: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  recipe?: InputMaybe<RecipeQl>;
};

export type TransportListResponseQl = {
  __typename?: 'TransportListResponseQL';
  totalTransports: Scalars['Int']['output'];
  transports?: Maybe<Array<Maybe<TransportResultQl>>>;
};

export type TransportMatrixResponseQl = {
  __typename?: 'TransportMatrixResponseQL';
  response?: Maybe<Array<Maybe<Array<Maybe<TransportGroupValueQl>>>>>;
};

export type TransportResultQl = {
  __typename?: 'TransportResultQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  legIndex: Scalars['Int']['output'];
  legs?: Maybe<Array<Maybe<LegQl>>>;
  price?: Maybe<MoneyQl>;
};

export enum TransportType {
  Ferry = 'Ferry',
  Flight = 'Flight',
  None = 'None',
}

export enum TransportVehicleType {
  Bicycle = 'Bicycle',
  MiniBus4mHigh6mLong = 'MiniBus4mHigh6mLong',
  MiniBus8mLong = 'MiniBus8mLong',
  MotorBike = 'MotorBike',
  MultiPurposeVehicle = 'MultiPurposeVehicle',
  None = 'None',
  Vehicle4m70cmLong = 'Vehicle4m70cmLong',
  Vehicle6mLong = 'Vehicle6mLong',
}

export type MoneyFragment = {
  __typename?: 'MoneyQL';
  amount?: any | null;
  currency?: string | null;
  currencySymbol?: string | null;
};

export type TicketFragment = {
  __typename?: 'TicketIngredientResponseQL';
  code?: string | null;
};

export type GetCheapestItineratyQueryVariables = Exact<{
  recipe: RecipeQl;
}>;

export type ValidateRecipeQueryVariables = Exact<{
  recipe: RecipeQl;
}>;

export type ValidateRecipeQuery = {
  __typename?: 'Query';
  validateRecipe?: boolean;
};

export type GetCheapestItineratyQuery = {
  __typename?: 'Query';
  cheapestItinerary?: {
    __typename?: 'ItineraryResponseQL';
    itineraryKey: string;
    totalPrice?: {
      __typename?: 'MoneyQL';
      amount?: any | null;
      currency?: string | null;
      currencySymbol?: string | null;
    } | null;
    result?: Array<
      | { __typename?: 'FerryIngredientResponseQL' }
      | { __typename?: 'FlightIngredientResponseQL'; date?: Date | null }
      | {
          __typename?: 'HotelIngredientResponseQL';
          lengthOfStay?: {
            __typename?: 'LengthOfStayQL';
            numberOfNights: number;
          } | null;
          suggested?: {
            __typename?: 'HotelStayResponseQL';
            hotelCode: string;
          } | null;
          subIngredientResults?: Array<
            | { __typename?: 'FerryIngredientResponseQL' }
            | { __typename?: 'FlightIngredientResponseQL' }
            | { __typename?: 'HotelIngredientResponseQL' }
            | { __typename?: 'OwnStayResultQL' }
            | {
                __typename?: 'TicketIngredientResponseQL';
                code?: string | null;
              }
            | { __typename?: 'TransportIngredientResponseQL' }
            | null
          > | null;
        }
      | { __typename?: 'OwnStayResultQL' }
      | { __typename?: 'TicketIngredientResponseQL' }
      | { __typename?: 'TransportIngredientResponseQL' }
      | null
    > | null;
  } | null;
};

export type GetAiRecipeQueryVariables = Exact<{
  request: RecipeFromTextRequestQl;
}>;

export type GetThreadAiQueryVariables = Exact<{
  request: ThreadAiRequestQl;
}>;

export type GetAiRecipeQuery = {
  __typename?: 'Query';
  recipeFromText?: {
    __typename?: 'RecipeFromTextResponseQL';
    success?: boolean | null;
    recipeJson?: string | null;
    additionalTexts?: {
      __typename?: 'AdditionalTextQL';
      inspirationalMessage?: string | null;
      id?: string | null;
      destinations?: Array<{
        __typename?: 'DestinationAdditionalTextQL';
        name?: string | null;
        activityTexts?: Array<string | null> | null;
      } | null> | null;
    } | null;
    missingProperties?: Array<{
      __typename?: 'MissingPropertyQL';
      defaultValue?: string | null;
      type?: MissingPropertyType | null;
    } | null> | null;
  } | null;
};

export type GetThreadAiQuery = {
  __typename?: 'Query';
  getThreadAI?: {
    __typename?: 'AIThreadResponseQL';
    recipeJson?: string | null;
    additionalTexts?: {
      __typename?: 'AdditionalTextQL';
      inspirationalMessage?: string | null;
      id?: string | null;
      destinations?: Array<{
        __typename?: 'DestinationAdditionalTextQL';
        name?: string | null;
        activityTexts?: Array<string | null> | null;
      } | null> | null;
    } | null;
    threadId?: string | null;
    messageId?: string | null;
    sessionId?: string | null;
    replyToUser?: string | null;
  } | null;
};

export const MoneyFragmentDoc = gql`
  fragment Money on MoneyQL {
    amount
    currency
    currencySymbol
  }
`;
export const TicketFragmentDoc = gql`
  fragment Ticket on TicketIngredientResponseQL {
    code
  }
`;
export const GetCheapestItineratyDocument = gql`
  query getCheapestItineraty($recipe: RecipeQL!) {
    cheapestItinerary(recipe: $recipe) {
      itineraryKey
      totalPrice {
        amount
        ...Money
      }
      result {
        ... on HotelIngredientResponseQL {
          lengthOfStay {
            numberOfNights
          }
          suggested {
            hotelCode
          }
          subIngredientResults {
            ...Ticket
          }
        }
        ... on FlightIngredientResponseQL {
          date
        }
      }
    }
  }
  ${MoneyFragmentDoc}
  ${TicketFragmentDoc}
`;

/**
 * __useGetCheapestItineratyQuery__
 *
 * To run a query within a React component, call `useGetCheapestItineratyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheapestItineratyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheapestItineratyQuery({
 *   variables: {
 *      recipe: // value for 'recipe'
 *   },
 * });
 */
export function useGetCheapestItineratyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCheapestItineratyQuery,
    GetCheapestItineratyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCheapestItineratyQuery,
    GetCheapestItineratyQueryVariables
  >(GetCheapestItineratyDocument, options);
}
export function useGetCheapestItineratyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheapestItineratyQuery,
    GetCheapestItineratyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCheapestItineratyQuery,
    GetCheapestItineratyQueryVariables
  >(GetCheapestItineratyDocument, options);
}
export type GetCheapestItineratyQueryHookResult = ReturnType<
  typeof useGetCheapestItineratyQuery
>;
export type GetCheapestItineratyLazyQueryHookResult = ReturnType<
  typeof useGetCheapestItineratyLazyQuery
>;
export type GetCheapestItineratyQueryResult = Apollo.QueryResult<
  GetCheapestItineratyQuery,
  GetCheapestItineratyQueryVariables
>;

export const ValidateRecipeDocument = gql`
  query validateRecipe($recipe: RecipeQL!) {
    validateRecipe(recipe: $recipe)
  }
`;

export function useValidateRecipeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateRecipeQuery,
    ValidateRecipeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateRecipeQuery, ValidateRecipeQueryVariables>(
    ValidateRecipeDocument,
    options
  );
}

export type ValidateRecipeQueryHookResult = ReturnType<
  typeof useValidateRecipeQuery
>;

export const GetAiRecipeDocument = gql`
  query getAiRecipe($request: RecipeFromTextRequestQL!) {
    recipeFromText(request: $request) {
      success
      recipeJson
      additionalTexts {
        inspirationalMessage
        destinations {
          name
          activityTexts
        }
        id
      }
      missingProperties {
        defaultValue
        type
      }
    }
  }
`;

/**
 * __useGetAiRecipeQuery__
 *
 * To run a query within a React component, call `useGetAiRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiRecipeQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetAiRecipeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAiRecipeQuery,
    GetAiRecipeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAiRecipeQuery, GetAiRecipeQueryVariables>(
    GetAiRecipeDocument,
    options
  );
}
export function useGetAiRecipeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAiRecipeQuery,
    GetAiRecipeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAiRecipeQuery, GetAiRecipeQueryVariables>(
    GetAiRecipeDocument,
    options
  );
}
export type GetAiRecipeQueryHookResult = ReturnType<typeof useGetAiRecipeQuery>;
export type GetAiRecipeLazyQueryHookResult = ReturnType<
  typeof useGetAiRecipeLazyQuery
>;
export type GetAiRecipeQueryResult = Apollo.QueryResult<
  GetAiRecipeQuery,
  GetAiRecipeQueryVariables
>;

export const GetThreadAiDocument = gql`
  query getThreadAI($request: ThreadAIRequestQL!) {
    getThreadAI(request: $request) {
      recipeJson
      additionalTexts {
        inspirationalMessage
        destinations {
          name
          activityTexts
        }
        id
      }
      threadId
      messageId
      sessionId
      replyToUser
    }
  }
`;

/**
 * __useGetThreadAiQuery__
 *
 * To run a query within a React component, call `useGetThreadAiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadAiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadAiQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetThreadAiQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetThreadAiQuery,
    GetThreadAiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadAiQuery, GetThreadAiQueryVariables>(
    GetThreadAiDocument,
    options
  );
}
export function useGetThreadAiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadAiQuery,
    GetThreadAiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadAiQuery, GetThreadAiQueryVariables>(
    GetThreadAiDocument,
    options
  );
}
export type GetThreadAiQueryHookResult = ReturnType<typeof useGetThreadAiQuery>;
export type GetThreadAiLazyQueryHookResult = ReturnType<
  typeof useGetThreadAiLazyQuery
>;
export type GetThreadAiQueryResult = Apollo.QueryResult<
  GetThreadAiQuery,
  GetThreadAiQueryVariables
>;
