import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { RecipeQl } from '../../data/smart-search/hooks';
import { addGAParameter } from '../../dtos/own/helpers';
import { decodeJsonRecipe } from '../../dtos/smart-search/helpers';
import { useTranslate } from '../../hooks/useTranslate';
import { PlusIcon } from '../../icons/PlusIcon';
import { ShoppingCartIcon } from '../../icons/ShopingCartIcon';
import {
  InspireProvider,
  useCheapestItineraryWithProductDataAndMetrics,
} from '../hooks/super-hook';
import ProductCard from './ProductCard';
import {
  BookButton,
  BookButtonText,
  ComponentContainer,
  GlobalColors,
  PlusBox,
  Previews,
  ShoppingCartIconContainer,
  Text,
} from './styles';
import TitleText from './TitleText';

export type FeatureToggle = 'enablePrice';

type PropType = {
  url: string;
  features?: FeatureToggle[];
  title?: string;
  subTitle?: string;
  maxWidth?: number;
  customText?: string;
  recipe: string;
};

const widgetMaxWidth = 750;
const widgetMinWidth = 350;

function validateProps(props: PropType): PropType {
  const validatedProps: PropType = { ...props };
  if (!validatedProps.maxWidth) validatedProps.maxWidth = widgetMaxWidth;
  if (validatedProps.maxWidth < widgetMinWidth)
    validatedProps.maxWidth = widgetMinWidth;
  if (validatedProps.maxWidth > widgetMaxWidth)
    validatedProps.maxWidth = widgetMaxWidth;
  return validatedProps;
}

export default function BookComponentType1(props: PropType) {
  return (
    <InspireProvider
      componentName="BookComponentType1"
      metricsGateway="http://localhost:48080/metrics"
    >
      <ErrorBoundary>
        <BookComponent {...validateProps(props)} />
      </ErrorBoundary>
    </InspireProvider>
  );
}

function BookComponent({ url, title, subTitle, customText, recipe }: PropType) {
  const recipeQl: RecipeQl = JSON.parse(decodeJsonRecipe(recipe));

  const { cheapestItineraryData, productData } =
    useCheapestItineraryWithProductDataAndMetrics({
      recipe: { ...recipeQl, startDate: undefined, endDate: undefined },
      widgetTitle: title,
    });
  const bestPrice = cheapestItineraryData?.cheapestItinerary;

  const { t } = useTranslate();

  const openTripPlanner = () => {
    window.open(addGAParameter(url), '_blank');
  };

  return (
    <>
      {bestPrice && (
        <>
          <GlobalColors />
          <ComponentContainer>
            {title && <TitleText line1={title} line2={subTitle} />}
            {productData && (
              <Previews quantity={productData.length}>
                <>{productData.map(ProductCard)}</>
                {productData.length > 1 && (
                  <PlusBox>
                    <PlusIcon />
                  </PlusBox>
                )}
              </Previews>
            )}
            {customText && <Text>{customText}</Text>}
            <Text>
              {`${bestPrice?.totalPrice?.amount} ${bestPrice?.totalPrice?.currency}`}
            </Text>
            <BookButton onClick={openTripPlanner}>
              <ShoppingCartIconContainer>
                <ShoppingCartIcon />
              </ShoppingCartIconContainer>
              <BookButtonText>{t('ui.action.book')}</BookButtonText>
            </BookButton>
          </ComponentContainer>
        </>
      )}
    </>
  );
}
