import { parse } from 'node-html-parser';

export const sanitize = (input: string): string => {
  const root = parse(input);
  for (const elm of root.querySelectorAll('*')) {
    const attributes = elm.rawAttrs.split(' ');
    for (const attrib of attributes) {
      if (attrib.startsWith('on')) {
        const [name] = attrib.split('=');
        elm.removeAttribute(name);
      }
    }
  }
  return root.toString();
};
