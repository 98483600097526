import * as Sentry from '@sentry/nextjs';
import { Statsig } from 'statsig-react';

import { isSSR, propertiesAsStrings } from '../../utils/helpers';
import { AllowedEventNames } from './LogService.types';

class LogServiceClass {
  logEvent(
    event: AllowedEventNames,
    value?: Parameters<typeof Statsig.logEvent>[1],
    metadata?: Record<string, string | null | undefined> | null | undefined
  ) {
    Statsig.logEvent(event, value, {
      ...(!isSSR() ? (window.location as any) : {}),
      ...propertiesAsStrings(metadata),
    });
    window.dataLayer?.push({ event, metadata });
  }

  logError = Sentry.captureException;
}

export const LogService = new LogServiceClass();
