import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { PropsWithChildren, useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import {
  ItineraryResponseQl,
  RecipeQl,
  useGetCheapestItineratyQuery,
} from '../../data/smart-search/hooks';
import { getProductData } from '../../dtos/own/api-helpers';
import { getUrl } from '../../dtos/own/recipeHelpers';
import { getMarket } from '../../utils/markets';
import { ErrorText } from '../errors/errorText';
import { WidgetError } from '../errors/widgetError';

const getUnixTimestamp = () => new Date().getTime();

type PropType = {
  recipe: RecipeQl;
  widgetTitle?: string;
  serverRendered?: boolean;
  disableMetrics?: boolean;
};

// We don't use this anymore I think?
const useMetrics = () => ({
  observe: (a: any, b: any, c: any) => {
    /* noop */
  },
});

export function useCheapestItineraryWithProductDataAndMetrics({
  recipe,
  widgetTitle,
  serverRendered,
  disableMetrics,
}: PropType) {
  if (serverRendered) return {};
  const startTime = getUnixTimestamp();

  // it's because we're returning early if SSR. No harm no foul?
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const metrics = disableMetrics ? null : useMetrics();

  const {
    data: cheapestItineraryData,
    error: itineraryDataError,
    loading: loadingItineraryData,
  } =
    // it's because we're returning early if SSR. No harm no foul?
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useGetCheapestItineratyQuery({
      variables: {
        recipe,
      },
      onError: () => {
        if (metrics && metrics.observe) {
          const itineraryLoadingTimeSpan = getUnixTimestamp() - startTime;
          // we are not in a web component
          metrics.observe(
            'cheapest_itinerary_execution_time',
            {
              no_results: 'true',
              error: 'true',
            },
            itineraryLoadingTimeSpan
          );
        }
      },
      onCompleted: (onCompletedData) => {
        const hasResults = onCompletedData && onCompletedData.cheapestItinerary;

        if (metrics && metrics.observe) {
          const itineraryLoadingTimeSpan = getUnixTimestamp() - startTime;
          // we are not in a web component
          metrics.observe(
            'cheapest_itinerary_execution_time',
            {
              no_results: !hasResults ? 'true' : 'false',
              error: 'false',
            },
            itineraryLoadingTimeSpan
          );
        }

        if (hasResults) return;
      },
    });

  // it's because we're returning early if SSR. No harm no foul?
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { error: productDataError, data: productData } = useQuery(
    ['cheapestItineraryProductData', cheapestItineraryData?.cheapestItinerary],
    async () => {
      const market = getMarket(recipe.market);

      if (
        market &&
        cheapestItineraryData &&
        cheapestItineraryData?.cheapestItinerary
      ) {
        return await getProductData(
          cheapestItineraryData?.cheapestItinerary as ItineraryResponseQl,
          market
        );
      }
      return [];
    },
    {
      retry: 3,
      retryDelay: 1000,
      enabled: Boolean(
        cheapestItineraryData && cheapestItineraryData.cheapestItinerary
      ),
      onSettled: (data, error) => {
        if (metrics && metrics.observe) {
          const getProductDataTimeSpan = getUnixTimestamp() - startTime;
          metrics.observe(
            'get_product_data_execution_time',
            {
              no_results: data?.length ? 'false' : 'true',
              error: error ? 'true' : 'false',
            },
            getProductDataTimeSpan
          );
        }
      },
    }
  );

  // it's because we're returning early if SSR. No harm no foul?
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (itineraryDataError) {
      const error = new WidgetError(
        ErrorText.smartSearchFailed,
        getUrl(recipe),
        widgetTitle,
        JSON.stringify(recipe),
        itineraryDataError
      );
    }
    // yeah I'm not messing with this right now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itineraryDataError]);

  // it's because we're returning early if SSR. No harm no foul?
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (productDataError) {
      const error = new WidgetError(
        ErrorText.contentApiFailed,
        getUrl(recipe),
        widgetTitle,
        JSON.stringify(recipe),
        new Error(JSON.stringify(productDataError))
      );
    }
    // yeah I'm not messing with this right now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDataError]);

  return {
    productData,
    cheapestItineraryData,
    loadingItineraryData,
    itineraryDataError,
    productDataError,
  };
}

interface InspireProviderProps {
  componentName: string;
  metricsGateway: string;
  disableMetrics?: boolean;
}

const queryClient = new QueryClient();

export const InspireProvider: React.FC<
  InspireProviderProps & PropsWithChildren<unknown>
> = ({ children, componentName, metricsGateway, disableMetrics }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DefaultApolloProvider>
        {disableMetrics ? children : <ErrorBoundary>{children}</ErrorBoundary>}
      </DefaultApolloProvider>
    </QueryClientProvider>
  );
};

type Props = {
  children?: React.ReactNode;
};

type CreateClientOptions = {
  httpUrl: string;
};

export const DefaultApolloProvider: React.FC<Props> = ({ children }) => (
  <ApolloProvider client={defaultApolloClient}>{children}</ApolloProvider>
);

const createApolloClient = ({ httpUrl }: CreateClientOptions) => {
  const httpLink = new HttpLink({
    headers: {},
    uri: (_) => `${httpUrl}`,
    // fetchOptions: {
    //     mode: 'no-cors',
    // },
  });

  const apolloLink = split(({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'query'
    );
  }, httpLink);

  return new ApolloClient<Record<string, unknown>>({
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
        // fetchPolicy: 'cache-and-network'
      },
    },
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          //graphQLErrors.forEach((error) =>
          // logger.warn({  TODO LOG
          //   eventId: 'GRAPHQL_ERROR',
          //   details: error
          // })
          //)
        }
        if (networkError) {
          //   logger.error({ TODO LOG
          //     eventId: 'GRAPHQL_NETWORK_ERROR',
          //     details: networkError,
          //   })
        }
      }),
      apolloLink,
    ]),
  });
};

export const defaultApolloClient = createApolloClient({
  httpUrl: 'https://smart-search.sembo.com/graphql',
});
