import { createContext, PropsWithChildren, useContext } from 'react';

import { GetCopyResult } from '../data/sanity/types';

interface Props {
  lang: string;
  microcopy?: GetCopyResult;
  macrocopy?: GetCopyResult;
}

export const TranslationContext = createContext<Partial<Props>>({
  lang: undefined,
  microcopy: undefined,
  macrocopy: undefined,
});

export function useTranslationContext(): Partial<Props> {
  return useContext(TranslationContext);
}

export const TranslationProvider: React.FC<
  Props & PropsWithChildren<unknown>
> = ({ children, ...rest }) => {
  return (
    <TranslationContext.Provider value={rest}>
      {children}
    </TranslationContext.Provider>
  );
};
