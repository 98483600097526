export type LineBreak = {
  line1: string;
  line2: string;
};

function removeLast(s: string[]) {
  const _s = [...s];
  _s.pop();
  return _s;
}

export function convertToLowerCase<T extends string>(value: T): Lowercase<T> {
  return value.toLowerCase() as Lowercase<T>;
}

export function breakLine(str: string, breakOnChar: number): LineBreak {
  if (str.length > breakOnChar) {
    const temp = str.slice(0, breakOnChar);
    const words = str.slice(0, breakOnChar).split(' ');
    let str1;
    if (
      words[words.length - 1].length > 3 &&
      str.slice(temp.length, temp.length + breakOnChar).trim().length > 3
    ) {
      str1 = temp + '-';
    } else if (
      words[words.length - 1].length > 3 &&
      str.slice(temp.length, temp.length + breakOnChar).trim().length <= 3
    ) {
      const temp2 = str.slice(
        0,
        breakOnChar -
          (4 - str.slice(temp.length, temp.length + breakOnChar).trim().length)
      );
      str1 = temp2 + '-';
      const line2 = str
        .slice(str1.length - 1, str1.length + breakOnChar)
        .trim();
      return { line1: str1.trim(), line2 };
    } else {
      str1 = removeLast(words).join(' ');
    }
    return {
      line1: str1.trim(),
      line2: str.slice(str1.length, str1.length + breakOnChar).trim(),
    };
  }
  return {
    line1: str,
    line2: '',
  };
}
